const formatObjectToString = (obj) => {
    console.log(obj)
    if (!obj) return 'No data available.';
  
    // Format number as currency
    const formatMoney = (number) => {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(number);
    };
  
    // Get the numeric value and its key
    const getNumericValue = (obj) => {
      const numericKeys = [
        'maxAmount', 
        'totalCost',
        'totalExpense', 
        'totalAmount', 
        'averageAmount',
        'count', 
        'maxCost',
        'averageExpense',
        'averageCost',
        'total_analog_lines',
        'totalExpenses',
        'total',
        'totalSum'
      ];
      for (const key of numericKeys) {
        if (obj[key] !== undefined) {
          return { key, value: obj[key] };
        }
      }
      return null;
    };
  
    // Get the classification/category/concept value and its key
    const getClassificationValue = (obj) => {
      const classKeys = [
        'generalClassification', 
        'maxCategory', 
        'maxConcept',
        'mostExpensiveConcept',
        'description',        
      ];
      for (const key of classKeys) {
        if (obj[key] !== undefined) {
          return { key, value: obj[key] };
        }
      }
      return null;
    };
  
    let result = '';
  
    // Add ID if it exists and isn't null
    if (obj._id && obj._id !== null) {
      result += obj._id;
    }
  
    // Add tn if it exists
    if (obj.tn) {
      result += obj.tn;
    }
  
    // Add classification if it exists
    const classification = getClassificationValue(obj);
    if (classification) {
      result += result ? ` ${classification.value}` : classification.value;
    }
  
    // Add numeric value at the end
    const numericValue = getNumericValue(obj);
    if (numericValue) {
      const formattedValue = numericValue.key === 'count' 
        ? numericValue.value 
        : formatMoney(numericValue.value);
      result += result ? ` ${formattedValue}` : formattedValue;
    }

    return result;
  };

export {
    formatObjectToString
}