import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  TextField,
  Typography,
  Paper,
  CircularProgress,
  AppBar,
  IconButton,
  Toolbar,
  Tooltip,
} from '@mui/material';
import axios from 'axios';
import logo from '../../assets/Cemex_logo.png';
import SendIcon from '@mui/icons-material/Send';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import DynamicTable from '../../components/DynamicTable';
import { formatObjectToString } from '../../utils';

const ChatInterface = () => {
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const [showHeader, setShowHeader] = useState(true);
  const [isListening, setIsListening] = useState(false);
  const [recognition, setRecognition] = useState(null);
  const [sessionId, setSessionId] = useState(Date.now().toString());

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if ('webkitSpeechRecognition' in window) {
      const recognition = new window.webkitSpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = true;
      
      recognition.onresult = (event) => {
        const transcript = Array.from(event.results)
          .map(result => result[0])
          .map(result => result.transcript)
          .join('');
        setUserInput(transcript);
      };

      recognition.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
        setIsListening(false);
      };

      setRecognition(recognition);
    }
  }, []);

  const toggleListening = () => {
    if (isListening) {
      recognition?.stop();
      setIsListening(false);
    } else {
      recognition?.start();
      setIsListening(true);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    // Check if the app is running inside an iframe
    if (window.self !== window.top) {
      setShowHeader(false);
    }
    setMessages((prev) => [
      ...prev,
      {
        role: 'assistant',
        content: `Hola, te puedo responder preguntas alrededor de los consumos de Granite.\
                  / Hello, I can answer questions regarding Granite expenses.
        `,
        type: 'text'
      }
    ]);
  }, []);
    

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsListening(false);

    if (!userInput.trim()) return;

    const userMessage = { role: 'user', content: userInput, type: 'text' };
    setMessages([...messages, userMessage]);
    setUserInput('');
    setLoading(true);

    const api = process.env.REACT_APP_BASE_URL;
    const token = process.env.REACT_APP_TOKEN;
    const url = `${api}/invoice/ask`;

    const payload = {
      query: userInput,
      // max_tokens: 500, // Limita a 500 tokens
    }

    try {
      const response = await axios.post(
        url,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.status === 200) {
        const {data} = response;
        const results = data.results;
        setSessionId(data.sessionId);

        if (data.totalResults > 1) {
          setMessages((prev) => [
            ...prev,
            {
              role: 'assistant',            
              type: 'table',
              data: results
            }
          ]);
        } else {
          const formattedResponse = formatObjectToString(results[0]);
          setMessages((prev) => [
            ...prev,
            {
              role: 'assistant',
              content: formattedResponse,
              type: 'text'
            }
          ]);
        } 
      }
           
    } catch (error) {
      const respuesta = 'Lo siento no sé como responder tu pregunta, dame más detalles por favor';
      setMessages((prev) => [
        ...prev,
        { role: 'assistant', 
          type: 'text',
          content: respuesta
        },
      ]);
      console.error('Error:', error);      
      
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        backgroundColor: '#f4f4f9',
      }}
    >
      {/* Fixed Header with Logo */}
      {
        showHeader &&
        <AppBar position="fixed" sx={{ backgroundColor: '#E7EAF4' }}>
          <Toolbar
            sx={{ display: 'flex', justifyContent: 'space-between'}}
          >
            <img
              src={logo}
              alt="Customer Logo"
              style={{ height: 40 }}
            />
            <Typography variant="h6" sx={{ color: '#0000B3'}}>
              Cemex-IT AI
            </Typography>
          </Toolbar>
        </AppBar>
      }
      
      {/* Main Chat Area */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          marginTop: '64px', 
          overflowY: 'auto',
          padding: 2,
        }}
      >
        {/* Chat Messages */}
        {messages.map((msg, index) => (
  
          (msg.type === 'text') ?          
            <Paper
              key={index}
              sx={{
                padding: 2,
                marginBottom: 1,
                backgroundColor: msg.role === 'user' ? '#bbdefb' : '#dfe1f2',
                alignSelf: msg.role === 'user' ? 'flex-end' : 'flex-start',
              }}
            >
              <Typography variant="body">
                {msg.content}
              </Typography>
            </Paper>
            :            
            <Box sx={{ marginBottom: 2 }}>
              {
                msg.data &&
                <>
                  <DynamicTable 
                    data={msg.data} 
                    title={" "}
                  />
                </>
              }                
            </Box>
        ))}
        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', margin: 1 }}>
            <CircularProgress size={24} />
          </Box>
        )}
        <div ref={messagesEndRef} />
      </Box>

      {/* User Input */}
      <Box
        component="form" 
        onSubmit={handleSubmit} 
        sx={{ 
          display: 'flex', 
          p: 2, 
          borderTop: 1, 
          borderColor: 'divider' 
        }}
      >
        <TextField
          fullWidth
          variant='outlined'
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          placehoder="Preguntas sobre consumos granite..."
          disabled={loading}
          sx={{ mr: 1 }}
        />
        <Tooltip title="Send">
          <IconButton 
            color={'primary'}
            onClick={handleSubmit}
          >
            <SendIcon/>
          </IconButton>          
        </Tooltip>
        {/* <IconButton 
          onClick={toggleListening}
        >
          { isListening ? <MicOffIcon /> : <MicIcon />}
        </IconButton> */}
      </Box>      
    </Box>
  );
};

export default ChatInterface;
