import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { Button, Box } from '@mui/material';
import _ from 'lodash';
import DownloadIcon from '@mui/icons-material/Download';

export default function DynamicTable({ data, title = 'Table', subtitle = 'data' }) {
  if (!data || !data.length) return null;

  const getHeaders = (data) => {
    const firstItem = data[0];
    let headers = [];

    Object.keys(firstItem).forEach(key => {
      if (key === '_id') {
        // If _id is an object, extract its keys
        if (typeof firstItem[key] === 'object') {
          Object.keys(firstItem[key]).forEach(nestedKey => {
            headers.push(nestedKey);
          });
        } else {
          // If _id is a string/number, add it as 'Value'
          headers.unshift('_id');
        }
      } else {
        // For numeric values, ensure they're added last
        if (typeof firstItem[key] === 'number') {
          headers.push(key);
        } else {
          headers.unshift(key);
        }
      }
    });

    return _.uniq(headers);
  };

  const headers = getHeaders(data);

  const formatHeaderText = (header) => {
    if (header === '_id') return 'Value';
    return header
      .replace(/([A-Z])/g, ' $1') // Add space before capital letters
      .replace(/^./, (str) => str.toUpperCase()) // Capitalize first letter
      .trim();
  };

  const rows = data.map((item, index) => {
    const row = { id: index }; 
    headers.forEach((header) => {
      row[header] = item._id && typeof item._id === 'object' && header in item._id
        ? item._id[header]
        : item[header];
    });
    return row;
  });

  const columns = headers.map((header, index) => ({
    field: header,
    headerName: formatHeaderText(header),
    flex: 1,
    sortable: true,
    valueFormatter: ({ value }) => {
      if (typeof value === 'string') {
        // Convert to uppercase and handle "(blank)"
        return value === '(blank)' ? '' : value.toUpperCase();
      } else if (index === headers.length - 1 && typeof value === 'number') {
        // Format as currency for the last column
        return value.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
        });
      } else if (typeof value === 'number') {
        // Format numbers
        // return value.toLocaleString('en-US', {
        //   minimumFractionDigits: 2,
        //   maximumFractionDigits: 2,
        // });
        return value;
      }
      return value; // Return other types as-is
    },
  }));

  const exportToCSV = () => {
    const headerRow = headers.map((header) => formatHeaderText(header)).join(',');
    const dataRows = rows.map((row) =>
      headers.map((header) => {
        const value = row[header];
        return typeof value === 'string' && value.includes(',')
          ? `"${value}"`
          : value;
      }).join(',')
    );

    const csvContent = [headerRow, ...dataRows].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute(
      'download',
      'report.csv'
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Card
      style={{
        backgroundColor: '#dfe1f2',
        height: '500px',
        width: '600px', // Set card width to 400px
        // margin: 'auto', // Center the card horizontally
      }}
    >
      <CardHeader title={title} />
      <CardContent style={{ height: '100%' }}>
        <Button
          onClick={exportToCSV}
          variant="contained"
          color="primary"
          style={{ marginBottom: '10px' }}
          startIcon={ <DownloadIcon/> }          
        >
          Excel
        </Button>
        <Box
          mt="2px"
          height={'54vh'}
          sx={{
              "& .MuiDataGrid-root": {
                  border: "none"
              },
              "& .MuiDataGrid-cell": {
                  borderBottom: "none"
              },
              "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#1976d2",
                  color: "#d3d4de",
                  borderBottom: "none"
              },
              "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: "#f0f0f0",
              },
              "& .MuiDataGrid-footerContainer": {
                  backgroundColor: "#1976d2",
                  color:  "#d3d4de",
                  borderTop: "none"
              },

          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10, 25, 50]}
            disableSelectionOnClick
          />
        </Box>        
      </CardContent>
    </Card>
  );
}
