import React from 'react';
import Openai from './pages/chat/Openai';

function App() {
  return (
    <Openai />
  )
}

export default App;
