import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
    baseQuery: fetchBaseQuery({ 
        baseUrl: process.env.REACT_APP_BASE_URL,
        prepareHeaders: (headers) => {
            const token = process.env.REACT_APP_TOKEN;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        }
    }),
    reducerPath: "salesApi",
    tagTypes: [
        "Branches",
        "Channel",
        "Calendar",
        "Products",
        "Sales",
        "Log",
        "Contact"
    ],
    endpoints: (build) => ({
        // sucursales de socios comerciales 
        getBranches: build.query({
            query: () => '/branch',
            providesTags: ["Branches"],
        }),
        // clientes por canal
        getChannels: build.query({
            query: () => '/channel',
            providesTags: ["Channel"],
        }),
        // calendario editorial
        getCalendar: build.query({
            query: () => '/calendar',
            providesTags: ["Calendar"],
        }),
        // catálogo de productos
        getProducts: build.query({
            query: ({page, limit}) => ({
                url:'/product',
                method: 'GET',
                params: {page, limit}
            }),
            providesTags: ["Products"],
        }),
        // Reporte de ventas
        getSales: build.query({
            query: ({ startDate, endDate }) => `/sales/summary?startDate=${startDate}&endDate=${endDate}`,
            providesTags: ["Sales"],
        }),
        // Bitácora de eventos
        getLog: build.query({
            query: ({ page}) => `/processlog?page=${page}`,
            providesTags: ["Log"]
        }),
        // lista contactos
        getContact: build.query({
            query: () => '/contact',
            providesTags: ["Contact"]
        }),
        // agregar contacto
        createContact: build.mutation({
            query: (formData) => ({
                url: '/contact',
                method: 'POST',
                body: formData
            }),
        }),
        // actualizar contacto
        updateContact: build.mutation({
            query: (formData) => ({
                url: `/contact/{id}`,
                method: 'PATCH',
                body: formData
            }),
        }),
    })
});

export const {
    useGetBranchesQuery,
    useGetChannelsQuery,
    useGetCalendarQuery,
    useGetProductsQuery,
    useGetSalesQuery,
    useGetLogQuery,
    useGetContactQuery,
    useCreateContactMutation,
    useUpdateContactMutation,
} = api;
